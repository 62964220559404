import React, { useState, useEffect, useContext } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"
import Questions from "./components/questions"
import Polls from "./components/polls"
import { clearClientData } from "../../services/auth"
import { TOKEN_EXPIRED } from "../../utils/constants"
import { useQuery } from "react-query"
import { pageASideContext } from "../../context/pageASide"

const PageAside = props => {
  const [pageAsideState, setpageAsideState] = useContext(pageASideContext)
  const [pollSpin, setPollSpin] = useState(false)
  const [questionsSpin, setQuestionsSpin] = useState(false)
  const [activeTab, setActiveTab] = useState("")
  const [chatopen, toggleChat] = useState(false)
  const [poll, setpoll] = useState({})
  const [questionsData, setQuestionsData] = useState(null)
  const {
    isLoading: pollsIsLoading,
    error: pollsLoadingError,
    data: pollsData = { result: { poll: [] } },
    refetch: pollsRefetch,
  } = useQuery(
    "polls",
    async () => {
      if (!props.isPollTabVisible) {
        return
      }
      const currentTime = Date.now()
      let response = await fetch(
        `${process.env.API_URL}/v2/${process.env.EVENT_ID}/polls`
      )
      if (response.status !== 200 && response.error === TOKEN_EXPIRED) {
        clearClientData()
      }
      response = await response.json()
      const afterFetch = Date.now()
      setTimeout(
        () => {
          setPollSpin(false)
        },
        1000 - (afterFetch - currentTime),
      )
      return response
    },
    {
      refetchInterval: 5000,
    },
  )
  const pollSubmit = async ({ selectionArray, presentationId, pollId }) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        pollId,
        poll: selectionArray,
        eventId: process.env.EVENT_ID,
      }),
    }
    const response = await fetch(
      process.env.API_URL + "/v2/" + presentationId + "/polls",
      requestOptions,
    ).then(res => {
      pollsRefetch()
      res.json()
    })

    if (
      response &&
      response.status === 200 &&
      response.result &&
      response.result.poll
    ) {
      setpoll(response.result)
    }
  }

  const fetchQuestions = async () => {
    const currentTime = Date.now()
    let response = await fetch(
      `${process.env.API_URL}/${process.env.EVENT_ID}/${props.presentationId}/questions?moderated=${props.moderatedQuestions}`,
    )
    response = await response.json()
    if (response && response.result) {
      const afterFetch = Date.now()
      setTimeout(
        () => {
          setQuestionsSpin(false)
        },
        1000 - (afterFetch - currentTime),
      )
      setQuestionsData(response.result)
    }
  }

  const updateQuestionsList = newQuestion => {
    questionsData.push(newQuestion)
    setQuestionsData(questionsData => [...questionsData])
  }

  useEffect(() => {
    if (props.isPollTabVisible) {
      setActiveTab("poll")
    } else if (props.isQuestionTabVisible) {
      setActiveTab("questions")
    } else if (props.hasLiveChat) {
      setActiveTab("rcchat")
    }

    if (props.isQuestionTabVisible) {
      fetchQuestions()
    }
  }, [])
  return (
    <aside
      className={`page-aside xs-between xs-column background-color-monochrome-1 color-secondary-2 ${props.className} ${pageAsideState ? "" : "page-aside-closed"}`}
    >
      <button
        className={"page-aside-toggle"}
        type="button"
        onClick={() => {
          setpageAsideState(!pageAsideState)
        }}
      >
        <FontAwesomeIcon
          icon={faChevronRight}
          className={"svg color-monochrome-2 text-size-regular chevron-right"}
        />
      </button>
      <div className={"tabs-widget"}>
        <div className={"tabs-header d-xs-flex xs-row"}>
          {props.isPollTabVisible && (
            <button
              onClick={() => setActiveTab("poll")}
              className={`button _a_tabs ${
                activeTab === "poll" ? "button--primary" : ""
              } ${props.isQuestionTabVisible || props.hasLiveChat ? "" : "width-full"}`}
              style={{ backgroundColor: activeTab !== "poll" ? "gray" : "white" }}
            >
              Poll
            </button>
          )}
          {props.isQuestionTabVisible && (
            <button
              onClick={() => setActiveTab("questions")}
              className={`button _a_tabs ${
                activeTab === "questions" ? "button--primary" : ""
              } ${props.isPollTabVisible || props.hasLiveChat ? "" : "width-full"}`}
              style={{ backgroundColor: activeTab !== "questions" ? "gray" : "white" }}

            >
              Questions
            </button>
          )}
          {props.hasLiveChat && (
            <button
              onClick={() => setActiveTab("rcchat")}
              className={`button _a_tabs ${
                activeTab === "rcchat" ? "button--primary" : ""
              } ${props.isQuestionTabVisible || props.isPollTabVisible ? "" : "width-full"}`}
              style={{ backgroundColor: activeTab !== "rcchat" ? "gray" : "white" }}

            >
              Chat
            </button>
          )}
        </div>
        <div
          className={`tabs-content  ${chatopen ? "tabs-content--short" : ""}`}
        >
          {activeTab === "poll" && props.isPollTabVisible && (
            <Polls
              poll={pollsData?.result?.questions}
              presentationId={pollsData?.result?.presentation_id}
              pollSubmit={pollSubmit}
              isVoted={pollsData?.result?.isVoted}
              status={pollsData?.status}
              fetchPolls={pollsRefetch}
              spin={pollSpin}
              setSpin={setPollSpin}
              pollId={pollsData?.result?._id}
            />
          )}
          {activeTab === "questions" && props.isQuestionTabVisible && (
            <Questions
              presentationId={props.presentationId}
              moderatedQuestions={props.moderatedQuestions}
              questionsData={questionsData}
              updateQuestionsList={updateQuestionsList}
              fetchQuestions={fetchQuestions}
              spin={questionsSpin}
              setSpin={setQuestionsSpin}
            />
          )}
          {activeTab === "rcchat" && props.hasLiveChat && (
            <div style={{ width: "100%", height: "100%" }}>
              <iframe
                style={{ width: "100%", height: "100%" }}
                className="resp-iframe networkingIframe"
                src={`${process.env.ROCKET_CHAT_URL}/channel/general?layout=embedded`}
                gesture="media"
                allow="encrypted-media"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </aside>
  )
}

export default PageAside
